export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1, user-scalable=no, maximum-scale=1"},{"hid":"description","name":"description","content":"Virtual Bike Store"},{"hid":"og:image","property":"og:image","content":"/thumbnail.jpg"},{"hid":"google","property":"google","content":"notranslate"}],"link":[{"rel":"icon","type":"image/png","href":"/favicon.png"}],"style":[],"script":[],"noscript":[],"title":"Virtual Bike Store","htmlAttrs":{"lang":"en","translate":"no"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const devPagesDir = null

export const devRootDir = null