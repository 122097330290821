import { default as arjg4h13LqmGMeta } from "/home/runner/work/demo-space/demo-space/src/pages/ar.vue?macro=true";
import { default as indexP0sYUt2gbqMeta } from "/home/runner/work/demo-space/demo-space/src/pages/index.vue?macro=true";
export default [
  {
    name: arjg4h13LqmGMeta?.name ?? "ar",
    path: arjg4h13LqmGMeta?.path ?? "/ar",
    meta: arjg4h13LqmGMeta || {},
    alias: arjg4h13LqmGMeta?.alias || [],
    redirect: arjg4h13LqmGMeta?.redirect || undefined,
    component: () => import("/home/runner/work/demo-space/demo-space/src/pages/ar.vue").then(m => m.default || m)
  },
  {
    name: indexP0sYUt2gbqMeta?.name ?? "index",
    path: indexP0sYUt2gbqMeta?.path ?? "/",
    meta: indexP0sYUt2gbqMeta || {},
    alias: indexP0sYUt2gbqMeta?.alias || [],
    redirect: indexP0sYUt2gbqMeta?.redirect || undefined,
    component: () => import("/home/runner/work/demo-space/demo-space/src/pages/index.vue").then(m => m.default || m)
  }
]