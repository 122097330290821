<template>
	<div class="errpage">
		<h1 v-if="error && error.message">{{ error.message }}</h1>
		<h1 v-else>Error</h1>
		<button @click="handleError">Back to homepage</button>
	</div>
</template>

<script setup lang="ts">
// const props = defineProps({
// 	error: Object,
// });

const error = useError();

if (error.value) {
	console.log(JSON.stringify(error.value))
	if (error.value instanceof Error) {
		error.value.message
	}
	else {
		error.value.statusCode == 404
		error.value.message = "Nothing here"
	}
}


const handleError = () => clearError({ redirect: '/' });
</script>


<style scoped lang="scss">
.errpage {
	height: 100vh;
	width: 100vw;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	display: flex;
	background: #0A0A0A;

	svg {
		max-width: 150px;
		height: auto;
		width: 80%;
	}

	h1 {
		margin: 20px 0 30px;
		font-size: 30px;
		max-width: 300px;
		text-align: center;
	}

	img {
		width: 500px;
		height: auto;
		position: absolute;
		left: calc(50% - 250px);
		pointer-events: none;
		opacity: 0.6;
		animation: blur 2s ease 0s infinite;
		animation-direction: alternate;
	}
}

@keyframes blur {

	0%,
	100% {
		-webkit-filter: blur(10px);
		-moz-filter: blur(10px);
		-o-filter: blur(10px);
		-ms-filter: blur(10px);
	}

	50% {
		-webkit-filter: blur(20px);
		-moz-filter: blur(20px);
		-o-filter: blur(20px);
		-ms-filter: blur(20px);
	}
}



@media (max-width: 750px) {
	.errpage {
		img {
			display: none;
		}
	}
}

.showLog {
	text-decoration: underline
}
</style>

