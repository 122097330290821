import VueGtag, { trackRouter } from 'vue-gtag-next'


// https://github.com/MatteoGabriele/vue-gtag
export default defineNuxtPlugin((nuxtApp) => {
	nuxtApp.vueApp.use(VueGtag, {
		property: {
			id: 'GA_MEASUREMENT_ID'
		}
	})
	trackRouter(useRouter())
})
