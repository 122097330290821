import { isTouchDevice } from '@ravespaceio/rave-engine/build/engine/src/utils/browser'


export default defineNuxtPlugin(nuxtApp => {

	// how to decalre types?
	// nuxtApp.provide('$isMobile', () => false) // throw error in terminal
	/**
	 * https://blog.logrocket.com/vue-js-globalproperties/
	 * https://stackoverflow.com/questions/63100658/add-global-variable-in-vue-js-3
	 */
	nuxtApp.vueApp.config.globalProperties.$isMobile = isTouchDevice()
	nuxtApp.vueApp.config.globalProperties.$isProd = process.env.NODE_ENV === 'production' // da sollte eig auch auf server side vue avaiablat sein. plugin script ist aber client only
	nuxtApp.vueApp.config.globalProperties.$gamepadActive = false

	window.addEventListener("gamepadconnected", function (e) {
		nuxtApp.vueApp.config.globalProperties.$gamepadActive = true
	});
	window.addEventListener("gamepaddisconnected", function (e) {
		nuxtApp.vueApp.config.globalProperties.$gamepadActive = false
	});

})
